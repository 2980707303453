




































































import { Component, Prop } from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICategory } from '@/interfaces/categories';
import { IBiomarker } from '@/interfaces/biomarkers';

@Component({ components: { ConfirmationModal } })
export default class CategoriesTable extends AppComponent {
@Prop({ default: [] }) public value: ICategory[];

@Prop({ default: true }) public showActions: boolean;
@Prop({ default: true }) public showSearch: boolean;
@Prop({ default: false }) public yieldActions: boolean;
@Prop({ default: 10 }) public itemsPerPage: number;

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'categoryName',
      align: 'left',
    },
    {
      text: 'Biomarkers',
      sortable: true,
      value: 'biomarkers',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search =  '';

  /**
   * Gets the biomarkers names from the biomarkers array
   * or returns 'None' if there are no biomarkers
   */
  public getBiomarkers(item: ICategory) {
    if (item && item.biomarkers && item.biomarkers.length > 0) {
      const biomarkers = item.biomarkers as IBiomarker[];
      return biomarkers.map((biomarker) => biomarker.name).join(', ');
    }
    return 'None';
  }

  /**
   * Edit the selected category from the list
   * If the yieldActions prop is true, emit the id of the category to be edited will
   * be sent to the parent component by a 'edit' event
   * otherwise, the user will be redirected to the edit categories page
   * @param categoryId the id of the category to be edited
   */
  public editCategory(categoryId: string) {
    if (this.yieldActions) {
      this.$emit('categories:edited', categoryId);
    } else {
      this.$router.push({
        name: 'main-content-categories-edit',
        params: { categoryId },
      });
    }
  }

  /**
   * Delete a category and refresh the screen
   */
  public async deleteCategory(categoryId: string) {
    this.$emit('categories:deleted', categoryId);
  }
}
