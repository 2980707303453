


















import {Component} from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import CategoryTable from '@/components/tables/CategoriesTable.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {readCategories} from '@/store/crud/getters';
import {
  dispatchDeleteCategory,
  dispatchGetBiomarkersForList,
  dispatchGetCategories,
} from '@/store/crud/actions';

@Component({components: {ConfirmationModal, CategoryTable}})
export default class Categories extends AppComponent {
  get categories() {
    const categories = readCategories(this.$store);
    if (categories) {
      return categories;
    } else {
      return [];
    }
  }
  public fetchingCategoriesData = false;
  /**
   * created hook, it only calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  /**
   * fetches needed data from the store/ server and displays
   * loading spinner
   */
  public async fetchData() {
    this.fetchingCategoriesData = true;
    await this.$nextTick();
    await dispatchGetCategories(this.$store);
    await dispatchGetBiomarkersForList(this.$store);
    this.fetchingCategoriesData = false;
  }

  /**
   * Delete a category and refresh the screen
   */
  public async deleteCategory(categoryId: string) {
    await dispatchDeleteCategory(this.$store, {categoryId});
    this.fetchData();
  }
}
